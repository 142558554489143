@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/quicksand.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url(../fonts/quicksand_bold.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 30px;
  font-family: "Quicksand";
  font-weight: 400;
}

#app-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-items {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}

.flex-item {
  flex-grow: 1;
  text-align: center;
}

.item-link {
  font-size: 22px;
  color: black;
  text-decoration: none;
}

.item-link:active {
  color: black;
}

.header-text {
  font-weight: 700;
}

.header-cursor {
  display: inline-block;
  width: 15px;
  height: 30px;
  background: #000;
  animation: blink-animation 1.5s steps(2, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(2, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
